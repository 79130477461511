import { useMutation, useQueryClient } from 'react-query'
import React, { useState, useEffect } from 'react'
import { Oval } from  'react-loader-spinner'
import { Checkbox } from '@mui/material';
import { registraRetorno } from '../../api/retornos'

import Button, { LocationButton } from '../button/Button';
import PopUp from '../popUpVazio/PopUpVazio';
import PopUpAlerta from './PopUp'

import './popUp.scss';
import './popUpSolicitaEntrega.scss'
import Input from '../input/Input';

const PopUpSolicitaRetorno = ({ os, setOs, trigger, setTrigger, solicitacao, atualizaRetornos, id, atualiza}) => {
    const [loader, setLoader] = useState(false);

    const [conteudoPopUp, setConteudoPopUp] = useState(""); 
    const [popUpAlerta, setPopUpAlerta] = useState(false);

    const idEmpresa = JSON.parse(localStorage.getItem("usuarioCash"))[0]["id"]
    
    const [formData, setFormData] = useState();
    const [numeroControle, setNumeroControle] = useState();
    const [inputFields, setInputFields] = useState([]);
    const [carregando, setcarregando] = useState(false);
    
    const queryClient = useQueryClient()
    
    const paradas = solicitacao?.paradas;
    
    useEffect(() => {

        if (paradas != undefined) {
            const fields = Object.entries(paradas).map(([key, parada], index) => ({
                label: `P${index + 1} - ${parada.endereco}`, 
                name: `P${index + 1}`, 
                placeholder: ''
            }));;
            setInputFields(fields);

            const initialFormData = Object.fromEntries(
                fields.map((field) => [field.name, { dinheiro: false, maquininha: false, comandaAssinada: false, valorDinheiro: '' }])
            );

            const initialNumeroControle = Object.fromEntries(
                fields.map((field) => [field.name, { numeroControle: '' }])
            );
            
            setNumeroControle(initialNumeroControle);
            setFormData(initialFormData);
        }
               
    }, [paradas]);
    
    const handleChange = (event, parada, metodo) => {
        const { name, value, checked, type } = event.target || {};
        
        if (type === 'checkbox' ){
            setFormData(prevState => ({
                ...prevState,
                [parada]: {
                    ...prevState[parada], 
                    [name]: type === 'checkbox' ? checked : value
                }
            }));
        }else{
            setFormData(prevState => ({
                ...prevState,
                [parada]: {
                    ...prevState[parada], 
                    [metodo]: event
                }
            }));
        }
    };


    
    const registraRetornos = async (formData) => {
        const promises = [];
    
        for (const [chave, retorno, key] of Object.entries(formData)) {
            if (retorno.comandaAssinada || retorno.dinheiro || retorno.maquininha) {
                const indice = parseInt(chave[1])-1;
                const entry = {
                    os: os,
                    idEmpresa: id,
                    idMotorista: solicitacao?.taxista_id,
                    numeroControle: numeroControle[chave].numeroControle,
                    numero_pedido: solicitacao?.paradas[indice].numero_pedido,
                    motorista: solicitacao?.nome_taxista,
                    telefone_motorista: solicitacao?.telefone_taxista,
                    nomeEmpresa: solicitacao?.nome_passageiro,
                    parada: inputFields.filter(field => field.name === chave)[0].label,
                    retorno: retorno,
                    finalizado: solicitacao?.data_hora_finalizacao,
                    data_solicitacao: solicitacao?.data_hora_solicitacao,
                    status: "Aguardando",
                    historico:[{data: new Date(), status: "Aguardando", operador: idEmpresa }]
                };
                
                const mutationPromise = new Promise((resolve, reject) => {
                    registraNovoRetorno.mutate(entry, {
                        onSuccess: resolve,
                        onError: reject
                    });
                });
    
                promises.push(mutationPromise);
            }
        }
        
        await Promise.all(promises);
        atualiza(true)

    };
    
    const lidarRegistroRetorno = async () => {
        try {
            setcarregando(true);
            await registraRetornos(formData);
            await atualizaRetornos();

            setcarregando(false);
            setTrigger(false);
            setOs('');
            setFormData();

        } catch (error) {
            console.error("Erro ao lidar com registro do retorno: ", error);
            setLoader(false);
        }
    };

    const onDeliveryRegistrationSucess = (e) =>{
        queryClient.invalidateQueries();
        if(e["status"] === 200){
            alert('Retorno registrado com sucesso!')
            atualiza(true)
        }
    }

    const onDeliveryRegistrationError = (e) =>{
        setcarregando(false)
        alert(e.response.data.data)
    }

    const registraNovoRetorno =  useMutation(registraRetorno, {
        onSuccess: (e) => {
            onDeliveryRegistrationSucess(e);
          
        },
        onError: (e) => {
            onDeliveryRegistrationError(e);
        }
    });
      
    return (
        trigger ?
            <PopUp trigger={trigger} setTrigger={setTrigger}>
                <div className='popUpConteudo' style={{width:'400px'}}>
                    <div style={{ color:"#BCB0B0", fontSize:"12px", marginBottom:"15px"}}> {"(*) - Campos Obrigatórios"}</div>
                     
                    {carregando == true &&
                        <div className='loader'>
                            <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={carregando} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
                        </div>
                    }

                    {inputFields&&formData&&inputFields.map(({ label, name }) => (
                        <div key={name}>
                            { label}
                            <Retorno formaPagamento={formData} numeroControle={numeroControle} setNumeroControle={setNumeroControle} parada={name} handleChange={ handleChange }/>
                            <br />
                        </div>
                    ))}

                    <div className='buttonRegistrar' style={{width: '400px'}}>
                        <Button 
                            onClick={() => {lidarRegistroRetorno()}}
                            width={"100px"}
                            color={"#ffffff"}
                            background={"rgb(66, 175, 60)"}
                            border={"rgb(66, 175, 60)"}
                            text={"Registrar"} 
                            margin={'auto'}
                        />

                    </div>
                    
                </div>
                <PopUpAlerta trigger={popUpAlerta} setTrigger={setPopUpAlerta}>
                    {
                        <div>
                            {conteudoPopUp}
                        </div>
                    }
                </PopUpAlerta>
            </PopUp>
            : null
    );
};


const Retorno = ( { formaPagamento, numeroControle, setNumeroControle, parada, handleChange })=>{

    const handleNumeroControle = (e) => {
        setNumeroControle(prevState => ({
            ...prevState,
            [parada]: {
                ...prevState[parada], 
                ['numeroControle']: e
            }
        }))
    }
    return <>
        <div>
        <div style={{display: 'flex'}}>
            <label>
                <Checkbox 
                    type="checkbox" 
                    name="dinheiro" 
                    checked={formaPagamento[parada].dinheiro} 
                    onChange={(e) => handleChange(e, parada)} 
                />
                Dinheiro
            </label>

            {formaPagamento[parada].dinheiro && (
                <div style={{marginLeft: 'auto'}}>
                <label style={{display: 'flex', marginLeft: '15px'}}>
                    <Input 
                        type="number" 
                        name="valorDinheiro" 
                        value={formaPagamento[parada].valorDinheiro}
                        placeholder="Valor (R$)" 
                        onChange={(e) => handleChange(e, parada, 'valorDinheiro')}
                        width={'100px'}
                        height={'20px'}
                    />
                </label> 
                </div>
            )}
        </div>
        
        <div style={{display: 'flex'}}>
            <label>
                <Checkbox 
                    type="checkbox" 
                    name="maquininha" 
                    checked={formaPagamento[parada].maquininha} 
                    onChange={(e) => handleChange(e, parada)}
                />
                Maquininha
            </label> <br />
            {formaPagamento[parada].maquininha && (
                <div style={{display: 'flex', marginLeft: 'auto'}}>
                    <label style={{display: 'flex', marginLeft: '15px'}}>
                        <Input 
                            type="number" 
                            name="idMaquininha" 
                            value={formaPagamento[parada].idMaquininha}
                            placeholder="ID Maquininha" 
                            onChange={(e) => handleChange(e, parada, 'idMaquininha')}
                            width={'110px'}
                            height={'20px'}
                        />
                    </label> 
                    <label style={{display: 'flex', marginLeft: '15px'}}>
                        <Input 
                            type="number" 
                            name="valorMaquininha" 
                            value={formaPagamento[parada].valorMaquininha}
                            placeholder="Valor (R$)" 
                            onChange={(e) => handleChange(e, parada, 'valorMaquininha')}
                            width={'100px'}
                            height={'20px'}
                        />
                    </label> 
                </div>
            )}
        </div>

        <label>
            <Checkbox 
                type="checkbox" 
                name="comandaAssinada" 
                checked={formaPagamento[parada].comandaAssinada} 
                onChange={(e) => handleChange(e, parada)}
            />
            Comanda Assinada
        </label> <br />
        </div>

        <Input
            name='numeroControle'
            value={numeroControle[parada].numeroControle}
            onChange={(e)=> handleNumeroControle(e)}
            placeholder={'Número de controle'}
            className="placeholder"
        />


    </>
    
}

export default PopUpSolicitaRetorno;
